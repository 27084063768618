import { render, staticRenderFns } from "./footer.vue?vue&type=template&id=6a7e51ea&scoped=true&"
import script from "./footer.vue?vue&type=script&lang=ts&"
export * from "./footer.vue?vue&type=script&lang=ts&"
import style0 from "./footer.vue?vue&type=style&index=0&id=6a7e51ea&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6a7e51ea",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VFooter } from 'vuetify/lib/components/VFooter';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VBtn,VDivider,VFooter,VImg,VRow})
