var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ApplicationBar',{attrs:{"badge-count":_vm.totalUnreadCount + _vm.totalMatchingCount},on:{"toggle":_vm.toggleMenu,"toggle-setting":function($event){_vm.spSettingDrawer = true}}}),_vm._v(" "),_c('v-navigation-drawer',{directives:[{name:"show",rawName:"v-show",value:(_vm.device === 'tablet' && _vm.drawer),expression:"device === 'tablet' && drawer"}],staticClass:"flex-row align-bottom",style:(_vm.testBar() ? 'margin-top: 12px;' : ''),attrs:{"hide-overlay:false":"","height":"54px","width":_vm.mainNaviWidth['tablet'] + _vm.subNaviWidth['tablet'],"app":"","permanent":""}},[_c('div',{staticClass:"d-flex align-center",staticStyle:{"gap":"40px","margin-left":"10px"}},[_c('ApplicationBarToggleButton',{attrs:{"open":""},on:{"handle-click":_vm.toggleMenu}}),_vm._v(" "),_c('v-item-group',[_c('v-item',[_c('UserProfile',{attrs:{"user-last-name":_vm.userStore.context.state.loginUser.user_last_name,"user-first-name":_vm.userStore.context.state.loginUser.user_first_name,"company-name":_vm.userStore.context.state.loginUser.company_name,"image-url":_vm.userStore.context.state.loginUser.company_logo_file &&
                (_vm.$fileBaseUrl + "/picture/bank/" + (_vm.userStore.context.state.loginUser.company_logo_file.file_unique_code))}})],1)],1)],1)]),_vm._v(" "),(_vm.device === 'pc' || (_vm.device === 'tablet' && _vm.drawer))?_c('v-navigation-drawer',{staticStyle:{"height":"calc(100% - 54px)","padding-bottom":"20px"},style:((_vm.adjustment + " border-top: 1px solid #DDE3EA")),attrs:{"hide-overlay:true":"","width":_vm.mainNaviWidth[_vm.device],"app":"","permanent":""}},[_c('div',{staticClass:"d-flex flex-column align-center",staticStyle:{"row-gap":"16px"},style:(_vm.device === 'pc' ? 'margin-top: 15px;' : 'margin-top: 20px;')},[(_vm.device === 'pc')?_c('NavigationMainButton',{attrs:{"icon-name":"mdi-menu"},on:{"handle-click":_vm.toggleMenu}}):_vm._e(),_vm._v(" "),_vm._l((_vm.items),function(item,index){return [(item.title === 'ホーム')?_c('NavigationMainButton',{key:index,attrs:{"icon-name":_vm.isMainItemSelected(item, _vm.currentPath)
              ? item.icon.selected
              : item.icon.default,"title":item.title,"selected":_vm.isMainItemSelected(item, _vm.currentPath),"badge-content":_vm.totalUnreadCount},on:{"handle-click":function($event){return _vm.navigateToPage(item.path)}}}):(item.title === '福利厚生')?_c('NavigationMainButton',{key:index,attrs:{"icon-name":_vm.isMainItemSelected(item, _vm.currentPath, _vm.currentHash)
              ? item.icon.selected
              : item.icon.default,"title":item.title,"selected":_vm.isMainItemSelected(item, _vm.currentPath, _vm.currentHash)},on:{"handle-click":function($event){item.path === '/fukuri'
              ? _vm.transferFukuri()
              : _vm.navigateToPage(item.path)}}}):(item.title === 'オープンイノベーション')?_c('NavigationMainButton',{key:index,attrs:{"icon-name":_vm.isMainItemSelected(item, _vm.currentPath)
              ? item.icon.selected
              : item.icon.default,"title":item.title,"selected":_vm.isMainItemSelected(item, _vm.currentPath),"badge":_vm.showCampfireNewBadge},on:{"handle-click":function($event){item.sub && _vm.navigateToPage(item.sub[0].path)}},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('span',{staticClass:"d-flex justify-center text-center",staticStyle:{"font-size":"10px","line-height":"1.2"}},[_vm._v("\n              オープン\n              "),_c('br'),_vm._v("\n              イノベーション\n            ")])]},proxy:true}],null,true)}):(item.title === 'クラウドファウンディング')?_c('NavigationMainButton',{key:index,attrs:{"icon-name":_vm.isMainItemSelected(item, _vm.currentPath)
              ? item.icon.selected
              : item.icon.default,"title":item.title,"selected":_vm.isMainItemSelected(item, _vm.currentPath),"badge":_vm.showCampfireNewBadge},on:{"handle-click":function($event){item.sub && _vm.navigateToPage(item.sub[0].path)}},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('span',{staticClass:"d-flex justify-center text-center",staticStyle:{"font-size":"10px","line-height":"1.2"}},[_vm._v("\n              クラウド\n              "),_c('br'),_vm._v("\n              ファウンディング\n            ")])]},proxy:true}],null,true)}):(item.title === 'ちゃんと請求書')?_c('NavigationMainButton',{key:index,attrs:{"icon-name":_vm.isMainItemSelected(item, _vm.currentPath)
              ? item.icon.selected
              : item.icon.default,"title":item.title,"selected":_vm.isMainItemSelected(item, _vm.currentPath),"badge":_vm.showPaymentNewBadge},on:{"handle-click":_vm.transferPayment},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('img',{staticStyle:{"width":"20px","height":"20px"},attrs:{"src":require("@/assets/img/sidenavi/receipt-text-outline.svg")}})]},proxy:true},{key:"title",fn:function(){return [_c('span',{staticClass:"d-flex justify-center text-center",staticStyle:{"font-size":"10px","line-height":"1.2"}},[_vm._v("\n              ちゃんと\n              "),_c('br'),_vm._v("\n              請求書\n            ")])]},proxy:true}],null,true)}):(item.path === '/mypage/chanto-attendance/application')?_c('NavigationMainButton',{key:index,attrs:{"icon-name":_vm.isMainItemSelected(item, _vm.currentPath)
              ? item.icon.selected
              : item.icon.default,"title":item.title,"selected":_vm.isMainItemSelected(item, _vm.currentPath)},on:{"handle-click":_vm.showIcnextApplicationModal}}):(item.title === '連携金融機関一覧')?_c('NavigationMainButton',{key:index,attrs:{"icon-name":_vm.isMainItemSelected(item, _vm.currentPath)
              ? item.icon.selected
              : item.icon.default,"title":item.title,"selected":_vm.isMainItemSelected(item, _vm.currentPath)},on:{"handle-click":function($event){return _vm.navigateToPage(item.path)}},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('span',{staticClass:"d-flex justify-center text-center",staticStyle:{"font-size":"10px","line-height":"1.2"}},[_vm._v("\n              連携金融機関\n              "),_c('br'),_vm._v("\n              一覧\n            ")])]},proxy:true}],null,true)}):(item.title === 'マッチング')?_c('NavigationMainButton',{key:index,attrs:{"icon-name":_vm.isMainItemSelected(item, _vm.currentPath)
              ? item.icon.selected
              : item.icon.default,"title":item.title,"selected":_vm.isMainItemSelected(item, _vm.currentPath),"badge-content":_vm.matchingUnrepliedCount},on:{"handle-click":function($event){return _vm.navigateToPage(item.path)}}}):_c('NavigationMainButton',{key:index,attrs:{"icon-name":_vm.isMainItemSelected(item, _vm.currentPath, _vm.currentHash)
              ? item.icon.selected
              : item.icon.default,"title":item.title,"selected":_vm.isMainItemSelected(item, _vm.currentPath, _vm.currentHash)},on:{"handle-click":function($event){return _vm.navigateToPage(item.path)}}})]})],2)]):_vm._e(),_vm._v(" "),(_vm.device !== 'sp' && _vm.drawer && _vm.currentSubItems.length)?_c('v-navigation-drawer',{staticStyle:{"height":"calc(100% - 54px)","padding-bottom":"20px"},style:((_vm.adjustment + " margin-left: " + (_vm.mainNaviWidth[_vm.device]) + "px; border-top: 1px solid #DDE3EA")),attrs:{"hide-overlay:true":"","mobile-breakpoint":480,"width":_vm.subNaviWidth[_vm.device],"app":""}},[_c('div',{staticClass:"nav",style:(_vm.device === 'pc' ? 'margin-top: 20px;' : 'margin-top: 30px;')},[_c('div',{staticClass:"menu d-flex flex-column",style:(_vm.device === 'tablet' ? 'gap:20px' : '')},[_vm._l((_vm.currentSubItems),function(item,index){return [(item.path === '/mypage/sc' && _vm.openSC)?_c('NavigationSubButton',{key:index,attrs:{"icon-name":_vm.currentPath === '/mypage/sc'
                ? item.icon.selected
                : item.icon.default,"title":item.title,"selected":_vm.currentPath === '/mypage/sc'},on:{"handle-click":function($event){return _vm.navigateToPage(item.path, _vm.device === 'tablet')}}}):(item.path === '/mypage/subsidy')?_c('NavigationSubButton',{key:index,attrs:{"icon-name":_vm.isSubItemSelected(item, _vm.currentPath) &&
                _vm.currentPath === item.path
                ? item.icon.selected
                : item.icon.default,"title":item.title,"selected":_vm.isSubItemSelected(item, _vm.currentPath) &&
                _vm.currentPath === item.path},on:{"handle-click":function($event){return _vm.navigateToPage(item.path, _vm.device === 'tablet')}}}):(item.path === '/mypage/subsidy/applysupport')?_c('NavigationSubButton',{key:index,attrs:{"icon-name":_vm.isSubItemSelected(item, _vm.currentPath) &&
                _vm.currentPath === item.path
                ? item.icon.selected
                : item.icon.default,"title":item.title,"selected":_vm.isSubItemSelected(item, _vm.currentPath) &&
                _vm.currentPath === item.path},on:{"handle-click":function($event){return _vm.navigateToPage(item.path, _vm.device === 'tablet')}}}):(!(item.path === '/mypage/sc' && !_vm.openSC))?[(item.path === '/fukuri')?_c('NavigationSubButton',{key:index,attrs:{"icon-name":_vm.isSubItemSelected(item, _vm.currentPath, _vm.currentHash)
                  ? item.icon.selected
                  : item.icon.default,"title":item.title,"selected":_vm.isSubItemSelected(item, _vm.currentPath, _vm.currentHash)},on:{"handle-click":_vm.transferFukuri}}):(item.path === '/mypage/crowdfunding/campfire')?_c('NavigationSubButton',{key:index,attrs:{"icon-name":_vm.isSubItemSelected(item, _vm.currentPath) &&
                  _vm.currentPath === item.path
                  ? item.icon.selected
                  : item.icon.default,"title":item.title,"selected":_vm.isSubItemSelected(item, _vm.currentPath) &&
                  _vm.currentPath === item.path,"font-small":_vm.device === 'pc'},on:{"handle-click":function($event){return _vm.navigateToPage(item.path, _vm.device === 'tablet')}}}):(
                _vm.device === 'pc' &&
                  item.path === '/mypage/business/succession/faq'
              )?_c('NavigationSubButton',{key:index,attrs:{"icon-name":_vm.isSubItemSelected(item, _vm.currentPath)
                  ? item.icon.selected
                  : item.icon.default,"title":item.title,"selected":_vm.isSubItemSelected(item, _vm.currentPath),"font-small":_vm.device === 'pc'},on:{"handle-click":function($event){return _vm.navigateToPage(item.path, _vm.device === 'tablet')}}}):(item.path === '/mypage/matching/company/')?_c('NavigationSubButton',{key:index,attrs:{"icon-name":item.path +
                  _vm.userStore.context.state.loginUser.company_unique_code ===
                  _vm.currentPath
                  ? item.icon.selected
                  : item.icon.default,"title":item.title,"selected":item.path +
                  _vm.userStore.context.state.loginUser.company_unique_code ===
                  _vm.currentPath},on:{"handle-click":function($event){return _vm.navigateToPage(
                  item.path +
                    _vm.userStore.context.state.loginUser.company_unique_code,
                  _vm.device === 'tablet'
                )}}}):(
                item.path === '/mypage/chanto-attendance/application'
              )?_c('NavigationSubButton',{key:index,attrs:{"icon-name":_vm.isSubItemSelected(item, _vm.currentPath) &&
                  _vm.currentPath === item.path
                  ? item.icon.selected
                  : item.icon.default,"title":item.title,"selected":_vm.isSubItemSelected(item, _vm.currentPath) &&
                  _vm.currentPath === item.path},on:{"handle-click":_vm.showIcnextApplicationModal}}):(
                item.path === '/chat'
              )?_c('NavigationSubButton',{key:index,attrs:{"icon-name":_vm.isSubItemSelected(item, _vm.currentPath, _vm.currentHash)
                  ? item.icon.selected
                  : item.icon.default,"title":item.title,"selected":_vm.isSubItemSelected(item, _vm.currentPath, _vm.currentHash),"font-bold":item.path === '/chat' && _vm.chatRoomsUnreadCount > 0,"badge-content":_vm.getBadgeCount(item)},on:{"handle-click":function($event){return _vm.navigateByHref(item.path)}}}):_c('NavigationSubButton',{key:index,attrs:{"icon-name":_vm.isSubItemSelected(item, _vm.currentPath, _vm.currentHash)
                  ? item.icon.selected
                  : item.icon.default,"title":item.title,"selected":_vm.isSubItemSelected(item, _vm.currentPath, _vm.currentHash),"font-bold":(item.path === '/mypage/bachat' &&
                  _vm.bachatRoomsUnreadCount > 0) ||
                  ((_vm.userStore.context.state.loginUser.user_type ===
                    'company_owner' ||
                    _vm.userStore.context.state.loginUser.user_type ===
                    'partner_company_owner') &&
                    item.path === '/mypage/news' &&
                    _vm.unreadCmiCount > 0) ||
                  (item.path === '/mypage/matching/received_history' &&
                    _vm.matchingUnrepliedCount > 0),"badge-content":_vm.getBadgeCount(item)},on:{"handle-click":function($event){return _vm.navigateToPage(item.path, _vm.device === 'tablet')}},scopedSlots:_vm._u([(item.path === '/mypage/setting/invoice')?{key:"icon",fn:function(){return [_c('img',{staticStyle:{"width":"20px","height":"20px","margin-right":"10px"},attrs:{"src":require("@/assets/img/sidenavi/receipt-text-outline.svg")}})]},proxy:true}:null],null,true)})]:_vm._e()]})],2)])]):_vm._e(),_vm._v(" "),(_vm.device === 'sp' && _vm.spSettingDrawer)?_c('SpSettingDrawer',{attrs:{"items":_vm.getSubItems('設定')},on:{"close":function($event){_vm.spSettingDrawer = false}}}):_vm._e(),_vm._v(" "),_c('FooterNavi',{attrs:{"items":_vm.items},on:{"show-icnext-application-modal":_vm.showIcnextApplicationModal}}),_vm._v(" "),(_vm.showIcnextDialog)?_c('icnextApplicationModal'):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }