import { render, staticRenderFns } from "./chat.vue?vue&type=template&id=27b0ee66&scoped=true&"
import script from "./chat.vue?vue&type=script&lang=ts&"
export * from "./chat.vue?vue&type=script&lang=ts&"
import style0 from "@/assets/css/spinner.less?vue&type=style&index=0&id=27b0ee66&scoped=true&lang=less&"
import style1 from "./chat.vue?vue&type=style&index=1&id=27b0ee66&scoped=true&lang=css&"
import style2 from "./chat.vue?vue&type=style&index=2&lang=css&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "27b0ee66",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VApp } from 'vuetify/lib/components/VApp';
import { VMain } from 'vuetify/lib/components/VMain';
import { VOverlay } from 'vuetify/lib/components/VOverlay';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';
installComponents(component, {VApp,VMain,VOverlay,VProgressCircular,VSnackbar})
